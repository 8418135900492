import * as Sentry from "@sentry/browser";

// Flags to enable/disable features
const replayEnabled = "false";
const errorFilteringEnabled = "false";
const feedbackEnabled = "false";

if (getEnv() !== "development") {
  try {
    const integrations = [
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ];

    // Conditionally add feedback integration based on the feedbackEnabled flag
    if (feedbackEnabled === "true") {
      integrations.push(
        Sentry.feedbackIntegration({
          showBranding: false,
          colorScheme: "light",
          // buttonLabel: "Give Feedback",
          submitButtonLabel: "Send Feedback",
          formTitle: "Give Feedback",
          messagePlaceholder: "What did you expect? Have you found a bug?",
          successMessageText: "Thanks for the feedback!",
        })
      );
    }

    if (replayEnabled === "true") {
      integrations.push(
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        })
      );
    }

    if (errorFilteringEnabled === "true") {
      integrations.push(
        Sentry.thirdPartyErrorFilterIntegration({
          // Specify the application keys that you specified in the Sentry bundler plugin
          filterKeys: ["findstack-js"],

          // Defines how to handle errors that contain third party stack frames.
          // Possible values are:
          // - 'drop-error-if-contains-third-party-frames'
          // - 'drop-error-if-exclusively-contains-third-party-frames'
          // - 'apply-tag-if-contains-third-party-frames'
          // - 'apply-tag-if-exclusively-contains-third-party-frames'
          behaviour: "drop-error-if-contains-third-party-frames",
        })
      );
    }

    Sentry.init({
      dsn: getMetaValue("sentry"),
      tracesSampleRate: 0.05,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 0.05,
      integrations: integrations,
    });
  } catch (error) {
    console.log("Unable to start Sentry");
  }
}

function getEnv() {
  const env = JSON.parse(document.body.getAttribute("data-constants")).env;
  return env;
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute("content");
}
